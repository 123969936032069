import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from './pages/HomePage';
import EpisodesPage from './pages/EpisodesPage';
import EpisodePage from './pages/EpisodePage';
import AboutPage from './pages/AboutPage';
import SubscribePage from './pages/SubscribePage';
import Footer from './components/Footer';

const App = () => (
  <Router>
    <div>
      <header>
        <Link to="/">
          <img src="/img/logo.svg" alt="Inka Time - Kid's Curiosity Podcast" className="logo" />
        </Link>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/episodes">Episodes</Link></li>
            <li><Link to="/about">About</Link></li>
          </ul>
        </nav>
      </header>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/episodes" element={<EpisodesPage />} />
        <Route path="/episode/:id" element={<EpisodePage />} /> {/* Add route for EpisodePage */}
        <Route path="/about" element={<AboutPage />} />
        <Route path="/subscribe" element={<SubscribePage />} />
      </Routes>
      <Footer />
    </div>
  </Router>
);

export default App;
