import React from 'react';
import EpisodeItem from './EpisodeItem';

const EpisodeList = ({ episodes }) => {
  const sortedEpisodes = [...episodes].sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <div className="episode-grid">
      {sortedEpisodes.map(episode => (
        <EpisodeItem key={episode.id} episode={episode} />
      ))}
    </div>
  );
};

export default EpisodeList;
