import React, { useState, useEffect } from 'react';
import EpisodeList from '../components/EpisodeList';
import Pagination from '../components/Pagination';
import Search from '../components/Search';
import episodesData from '../episodes.json';
import WelcomeSection from '../components/WelcomeSection';

const EpisodesPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [episodesPerPage] = useState(4);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEpisodes, setFilteredEpisodes] = useState(episodesData);

  useEffect(() => {
    setFilteredEpisodes(
      episodesData.filter(episode =>
        episode.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  const indexOfLastEpisode = currentPage * episodesPerPage;
  const indexOfFirstEpisode = indexOfLastEpisode - episodesPerPage;
  const currentEpisodes = filteredEpisodes.slice(indexOfFirstEpisode, indexOfLastEpisode);
  const totalPages = Math.ceil(filteredEpisodes.length / episodesPerPage);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const onSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  return (
    <div>
      <WelcomeSection />
      <div className="main-content">
        <div className="content">
          <h2>All Episodes</h2>
          <Search onSearch={onSearch} />
          <EpisodeList episodes={currentEpisodes} />
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
        </div>
      </div>
    </div>
  );
};

export default EpisodesPage;
