import React from 'react';
import { useParams } from 'react-router-dom';
import episodesData from '../episodes.json';
import WelcomeSection from '../components/WelcomeSection';

const EpisodePage = () => {
  const { id } = useParams();
  const episode = episodesData.find(ep => ep.id.toString() === id);

  if (!episode) {
    return <div>Episode not found</div>;
  }

  return (
    <div>
      <WelcomeSection />
      <div className="episode-page">
        <h1>{episode.title}</h1>
        <p><strong>Date:</strong> {episode.date}</p>
        <p>{episode.description}</p>
        <div className="embed-container">
          <iframe src={`https://podcasters.spotify.com/pod/show/austins-curiosity-podcast/embed/episodes/${episode.anchorEmbed}`} title={`Podcast Player for ${episode.title}`} height="102px" width="400px" frameborder="0" scrolling="no"></iframe>
        </div>
        <div className="transcript">
          <h2>Transcript</h2>
          <p>{episode.transcript}</p>
        </div>
      </div>
    </div>
  );
};

export default EpisodePage;
