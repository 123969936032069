import React from 'react';
import WelcomeSection from '../components/WelcomeSection';
const spotifyLogo = '../../img/spotify.svg';
const applePodcastsLogo ='../img/podcast.svg';

const SubscribePage = () => {
  return (
    <div>
      <WelcomeSection />
        <div className="subscribe-page">
        <h1>Subscribe to Inka Time</h1>
        <p>Join us on your favorite podcast platform to stay updated with our latest episodes:</p>
        <ul className="subscribe-options">
            <li>
            <a href="https://podcasts.apple.com/ca/podcast/inka-time-kids-curiosity-podcast/id1709840195" target="_blank" rel="noopener noreferrer">
                <img src={applePodcastsLogo} alt="Subscribe on Apple Podcasts" />
                <span>Apple Podcasts</span>
            </a>
            </li>
            <li>
            <a href="https://podcasters.spotify.com/pod/show/inka-time" target="_blank" rel="noopener noreferrer">
                <img src={spotifyLogo} alt="Listen on Spotify" />
                <span>Spotify</span>
            </a>
            </li>
        </ul>
        </div>
    </div>
  );
};

export default SubscribePage;
