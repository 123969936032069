import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';  // Add this import
import EpisodeList from '../components/EpisodeList';
import WelcomeSection from '../components/WelcomeSection';
import episodesData from '../episodes.json';

const HomePage = () => {
  const [latestEpisodes, setLatestEpisodes] = useState([]);

  useEffect(() => {
    const sortedEpisodes = [...episodesData].sort((a, b) => new Date(b.date) - new Date(a.date));
    setLatestEpisodes(sortedEpisodes.slice(0, 4));
  }, []);

  return (
    <div>
      <WelcomeSection />
      <div className="main-content">
        <div className="content">
          <h2>Latest Episodes</h2>
          <EpisodeList episodes={latestEpisodes} />
          <Link to="/episodes"><button>More episodes</button></Link> 
        </div>
      </div>
    </div>
  );
};

export default HomePage;
