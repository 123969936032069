import React from 'react';
import { Link } from 'react-router-dom';

const EpisodeItem = ({ episode }) => (
  <div className="episode-item">
    <Link to={`/episode/${episode.id}`}><img src={`/img/episodes/${episode.id}.png`} alt="Episode artwork" /></Link>
    <h3><Link to={`/episode/${episode.id}`}>{episode.title}</Link></h3>
    <time dateTime={new Date(episode.date).toISOString()}>{episode.date}</time>
    <p>{episode.description}</p>
    <Link to={`/episode/${episode.id}`}><button>View Details</button></Link>
  </div>
);

export default EpisodeItem;
