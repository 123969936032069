import React from 'react';
import WelcomeSection from '../components/WelcomeSection';

const AboutPage = () => {
  return (
    <div>
      <WelcomeSection />
      <div className="about-page">
        <h1>About Inka Time</h1>
        <p>Welcome to Inka Time - Kid's Curiosity Podcast! Our mission is to ignite curiosity and foster a love for learning in children of all ages. Join us as we explore fascinating topics, from historical landmarks to groundbreaking inventions, and everything in between. Hosted by Ryan and his delightful co-host, Inka the Pig, each episode is filled with engaging stories, fun facts, and infectious curiosity.</p>
        <p>Tune in and embark on a journey of discovery with us!</p>
      </div>
    </div>
  );
};

export default AboutPage;
