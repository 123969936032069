import React from 'react';
import { Link } from 'react-router-dom';

const WelcomeSection = () => {
  return (
    <div className="below-header">
      <div className="content">
        <h2>Welcome to Inka Time!</h2>
        <p>An informative podcast engaging kids and adult curiosities</p>
        <ul className="subscribe-list">
          <li>
            <a href="https://podcasts.apple.com/ca/podcast/inka-time-kids-curiosity-podcast/id1709840195" target="_blank" rel="noopener noreferrer">
              <img src="/img/apple-listen.svg" alt="Subscribe on Apple Podcasts" style={{ width: '165px' }} />
            </a>
          </li>
          <li>
            <a href="https://podcasters.spotify.com/pod/show/austins-curiosity-podcast" className='spotify-listen' target="_blank" rel="noopener noreferrer">
              <img src="/img/spotify-listen.png" alt="Listen on Spotify" style={{ width: '210px' }} />
            </a>
          </li>
        </ul>
        <Link to={`/subscribe`}><button>More ways to subscribe</button></Link>
      </div>
    </div>
  );
};

export default WelcomeSection;
